import { Button } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React, { Fragment } from 'react';

import ErrorPage from './ErrorPage';
import { Header } from '@Components/Header/Header';

export const NotFoundPage = () => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Header />
      <ErrorPage
        title={t('errorPage.newNotFound.title')}
        subtitle={t('errorPage.newNotFound.subtitle')}
        content={t('errorPage.newNotFound.content')}
        errorStatus="404"
        actions={
          <Fragment>
            <Button
              variant="PrimaryLegacy"
              size="48"
              href="/"
            >
              {t('exploreHolidays')}
            </Button>
            <Button
              variant="Outline"
              size="48"
              href="/customer/add-booking"
            >
              {t('manageBooking')}
            </Button>
          </Fragment>
        }
      />
    </Fragment>
  );
};

export default NotFoundPage;
